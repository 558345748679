import SliderModifier from '../slider-modifier/Slider-modifier.vue'

// Libraries
import {
  get as _get,
  cloneDeep as _cloneDeep
} from 'lodash-es'

// Component states
import states from './padding-modifier.state.js'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Padding-modifier'

// Properties
// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Components
// Vue@subComponents
const components = {
  SliderModifier
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states)
  }
}
// Vue@data

// Methods
const methods = {

  // Func@resetPadding
  /**
  * Callback to reset padding this.attrArr
  * @return {String}        (padding reset to 0)
  */
  resetPadding (active) {
    if (active) return
    const top = this.attrArr[0]
    const left = this.attrArr[3]

    this.attrStr = `${parseInt(top)}px ${parseInt(left)}px ${parseInt(top)}px ${parseInt(left)}px`
  },
  // Func@resetPadding

  // Func@paddingCheck
  /**
   * Init extendedPadding state; check if extension has been disabled
   */

  paddingCheck () {
    this.states.extendedPaddingOptions = ((this.attrArr[0] !== this.attrArr[2]) || (this.attrArr[1] !== this.attrArr[3]))
  },
  // Func@paddingCheck

  /**
   * Function to change any and all padding sides or pairs
   * @return {String} array string after alterations
   */

  onPaddingChanged ({ top = this.attrArr[0], right = this.attrArr[1], bottom = this.attrArr[2], left = this.attrArr[3] }) {
    if (this.isPaired) {
      this.attrStr = `${parseInt(top)}px ${parseInt(right)}px`
      return
    }
    this.attrStr = `${parseInt(top)}px ${parseInt(right)}px ${parseInt(bottom)}px ${parseInt(left)}px`
  },

  // Func@onPaddingTBChanged
  /**
   * Callback on padding Top/Bottom called
   * @param  {Number} newVal (new top/bot value)
   *
   * @return {String}        (padding top/bot css value)
   */
  onPaddingTBChanged (newVal) {
    this.onPaddingChanged({ top: newVal, bottom: newVal })
  },
  // Func@onPaddingTBChanged

  // Func@onPaddingLRChanged
  /**
   * Callback on padding Left/Right called
   * @param  {Number} newVal (new left/right value)
   *
   * @return {String}        (padding left/right css value)
   */
  onPaddingLRChanged (newVal) {
    this.onPaddingChanged({ left: newVal, right: newVal })
  },
  // Func@onPaddingLRChanged

  // Func@onPaddingTChanged
  /**
   * Callback on Top padding called
   * @param  {Number} newVal (new top value)
   *
   * @return {String}        (padding top css value)
   */
  onPaddingTChanged (newVal) {
    this.onPaddingChanged({ top: newVal })
  },
  // Func@onPaddingTChanged

  // Func@onPaddingRChanged
  /**
   * Callback on Right padding called
   * @param  {Number} newVal (new right value)
   *
   * @return {String}        (padding right css value)
   */

  onPaddingRChanged (newVal) {
    this.onPaddingChanged({ right: newVal })
  },
  // Func@onPaddingRChanged

  // Func@onPaddingBChanged
  /**
   * Callback on Bottom padding called
   * @param  {Number} newVal (new bottom value)
   *
   * @return {String}        (padding bottom css value)
   */
  onPaddingBChanged (newVal) {
    this.onPaddingChanged({ bottom: newVal })
  },
  // Func@onPaddingBChanged

  // Func@onPaddingLChanged
  /**
   * Callback on Left padding called
   * @param  {Number} newVal (new left value)
   *
   * @return {String}        (padding left css value)
   */
  onPaddingLChanged (newVal) {
    this.onPaddingChanged({ left: newVal })
  }
  // Func@onPaddingLChanged
}

// Computed methods
const computed = {

  // Func@attrStr
  /**
   * Get padding value in string type
   * @return {String} (padding value)
   */
  attrStr: {
    get () {
      return this.mjml.attributes[_get(this.conf, 'key') || 'padding']
    },
    set (value) {
      this.mjml.attributes[_get(this.conf, 'key') || 'padding'] = value
    }
  },
  // Func@attrStr

  // Func@attrArr
  /**
   * Convert attrStr to an array so we can change it above
   * @return {Array}
   */
  attrArr () {
    return this.attrStr.split(' ')
  },
  // Func@attrArr

  // Func@isPaired
  /**
   * Get paired boolean
   * @return {Boolean}
   */
  isPaired () {
    return _get(this.conf, 'disableExtendedPadding')
  },
  // Func@isPaired

  // Func@paddingTBorT
  /**
   * Get padding Top/Bottom value
   * @return {Number} (padding top/bottom value)
   */
  paddingTBorT () {
    return parseInt(this.attrArr[0])
  },
  // Func@paddingTBorT

  // Func@paddingRLorR
  /**
   * Get padding left/right value
   * @return {Number} (padding left/right value)
   */
  paddingRLorR () {
    return parseInt(this.attrArr[1])
  },
  // Func@paddingRLorR

  // Func@paddingB
  /**
   * Get bottom padding value
   * @return {Number} (padding bottom value)
   */
  paddingB () {
    return parseInt(this.attrArr[2])
  },
  // Func@paddingB

  // Func@paddingL
  /**
   * Get left padding value
   * @return {Number} (padding left value)
   */
  paddingL () {
    return parseInt(this.attrArr[3])
  },
  // Func@paddingL
  hasFixedHeight () {
    return this.mjml.tagName === "mj-button-v2" && this.mjml.attributes['height'].length > 0
  },
  hasFixedWidth () {
    return this.mjml.tagName === "mj-button-v2" && this.mjml.attributes['width'].includes('px')
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components,
  created: methods.paddingCheck
}
